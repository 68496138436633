import Standard from '../../js/Libraries/Standard';
import Handlers from '../../js/Libraries/Handlers';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import jump from 'jump.js';
/* 
  -fehler bei textupload in backend, was angezeigt???
  - 'data feld muss ausgefüllt sein, bisher ohne benarchitgn...
  - textarea text richtig abstimmen....
*/
class Navbar extends Standard {
  constructor() {
    super('#navbar');
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.http = new ZachiHttp();
    this.burger = super.set('.z-burger');
    this.pages = super.set('.z-nav-pages');
    this.navLinks = super.set('.z-nav-page', true);
    this.speed = 0.65;

    this.breackpoint = 600;
    this.img = super.set('.z-logo-container img');

    this.endpointLoadUser = super.setEndpoint('/users_api/load_user');
    this.endpointUser = super.setEndpoint('/users_api');
    this.endpointMessage = super.setEndpoint('/chats_api/get_count_new_messages');

    this.loginBtn = super.set('#loginBtn');
    this.signUpBtn = super.set('#signUpBtn');
    this.adminBtn = super.set('#adminBtn');
    this.userBtn = super.set('#userBtn');
    this.screenWidht = this.hl.widthOfScreen();
    this.isFirstLoad = true;
    this.init();
  }

  async init() {
    this.checkForRef();
    await this.loadUser();
    await this.loadUserName();
    this.events();
  }

  async checkForRef() {
    if (window.location.href.includes('#')) {
      const els = window.location.href.split('#');
      const ref = els[els.length - 1];
      if (ref) {
        const query = '#' + ref;
        if (document.querySelector(query)) {
          this.container.classList.remove('active');
          this.container.classList.add('navbar-opacity');
        }
      }
    }
  }

  events() {
    this.burger.addEventListener('click', () => {
      if (this.pages.dummy) {
        this.pages.style.animation = `navSlideOut ${this.speed}s ease backwards`;
        this.pages.dummy = '';
      } else {
        this.pages.style.animation = `navSlideIn ${this.speed}s ease forwards`;
        this.pages.dummy = 'x';
      }
      this.burger.classList.toggle('z-toggle');
      this.navLinks.forEach((link, index) => {
        var speed_pro = index / 8 + this.speed - 0.35;
        if (link.style.animation) {
          link.style.animation = '';
        } else {
          link.style.animation = `navLinkFade 0.5s ease forwards ${speed_pro}s`;
        }
      });
    });

    if (this.img !== null) {
      this.img.addEventListener('click', () => {
        window.location.href = '/';
      });
    }

    window.addEventListener(
      'scroll',
      async () => {
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
          return;
        }
        if (this.screenWidht < this.breackpoint) {
          this.container.classList.add('navbar-opacity');
          this.container.classList.add('active');
          return;
        }
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (!this.hl.elementInViewportAnyPart(this.container)) {
          this.container.classList.add('navbar-opacity');
        } else {
          this.container.classList.remove('navbar-opacity');
        }

        if (st >= this.lastScrollTop) {
          this.container.classList.remove('active');
        } else {
          this.container.classList.add('active');
        }

        this.lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );

    window.addEventListener('resize', () => {
      this.screenWidht = this.hl.widthOfScreen();
    });
  }

  async loadUser() {
    const res = await this.http.get(this.endpointLoadUser);

    if (res.status === 200) {
      if (res.result.is_logged === false) {
        this.loginBtn.classList.remove('display-btn-none');
        this.signUpBtn.classList.remove('display-btn-none');
      } else {
        this.adminBtn.classList.remove('display-btn-none');
        this.userBtn.classList.remove('display-btn-none');
      }
    }
  }

  async loadUserName() {
    const resLogged = await this.http.get(this.endpointLoadUser);
    if (resLogged.status === 200) {
      if (resLogged.result.is_logged === false) {
        return;
      }
    }
    const resUser = await this.http.get(this.endpointUser);
    const resMessage = await this.http.get(this.endpointMessage);
    if (resUser.status === 200 && resMessage.status === 200) {
      const name = resUser.result.first_name.split(' ');
      const el = super.set('#userBtnFirstName');
      const elHref = super.set('#userBtnFirstNameHref');
      if (parseInt(resMessage.result) === 0) {
        el.innerHTML = `<i class='fas fa-user'></i> ${name[0]}`;
        elHref.href = '/dashboard';
      } else {
        el.innerHTML = ` <span class='dashboard-navbar-drop--first-name'><i class='fas fa-user mr-1'></i> ${name[0]}</span>
        
        <span class='dashboard-navbar-drop--new-message-text'>Neue Nachricht!</span>
        `;
        elHref.href = '/dashboard/nachrichten';
      }
    }
  }
}

new Navbar();
