import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';
class Home extends Standard {
  constructor() {
    super('body');

    if (!super.exist()) return;

    this.http = new ZachiHttp();
    this.hl = new Handlers();

    this.HomeLinkAdminSignup = super.set('#HomeLinkAdminSignup');
    this.endpointLoadUser = super.setEndpoint('/users_api/load_user');
    this.init();
  }

  async setText() {
    const res = await this.http.get(this.endpointLoadUser);

    if (res.status === 200) {
      if (res.result.is_logged === true) {
        const content = this.HomeLinkAdminSignup.querySelector('.content');

        content.innerHTML = `
        <p class="text">
    Erstelle jetzt dein passendes Inserat und finde deinen perfekten Reisepartner!
    </p>
    <div class="text-center">
        <a href="/dashboard" class="btn home-btn">
            zum Userbereich</a>
    </div>
        `;
      }
    }
  }

  init() {
    this.setText();
  }
}

new Home();
